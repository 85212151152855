<template>
  <div>
    <div class="breadCurmb mt-3">
      <label>
        <a>{{ $t("Areas") }}</a>
        <span>/</span>
        <label active>{{ $t("Home") }}</label>
      </label>
    </div>

    <h2 class="my-5 mx-7">{{ $t("Areas") }}</h2>

    <v-card-text>
      <v-container fluid>
        <v-row>
          <v-col
            class="py-0"
            :cols="$vuetify.breakpoint.mdAndUp ? '2' : '12'"
            v-if="userDriver && userDriver.admin && userDriver.admin.city_id"
          >
            <div
              :class="{
                'has-error': errors.has(`addEditValidation.city_id`),
              }"
            >
              <label>{{ $t("City") }}</label>
              <v-autocomplete
                class="d-block my-2"
                name="city_id"
                data-vv-scope="addEditValidation"
                v-validate="'required'"
                :data-vv-as="$t('City')"
                hide-details
                dense
                outlined
                v-model="filter.city_id"
                item-value="id"
                item-text="name"
                :items="cityList"
                readonly
              ></v-autocomplete>
            </div>

            <div
              class="help-block"
              v-if="errors.has(`addEditValidation.city_id`)"
            >
              {{ errors.first(`addEditValidation.city_id`) }}
            </div>
          </v-col>

          <v-col class="py-0" :cols="$vuetify.breakpoint.mdAndUp ? '2' : '12'">
            <div
              :class="{
                'has-error': errors.has(`addEditValidation.period_id`),
              }"
            >
              <label>{{ $t("Period") }}</label>
              <v-autocomplete
                class="d-block my-2"
                name="period_id"
                data-vv-scope="addEditValidation"
                v-validate="'required'"
                :data-vv-as="$t('Period')"
                hide-details
                dense
                outlined
                v-model="filter.period_id"
                item-value="id"
                item-text="name"
                :items="periodList"
                clearable
              ></v-autocomplete>
            </div>

            <div
              class="help-block"
              v-if="errors.has(`addEditValidation.period_id`)"
            >
              {{ errors.first(`addEditValidation.period_id`) }}
            </div>
          </v-col>

          <v-col class="py-0" :cols="$vuetify.breakpoint.mdAndUp ? '2' : '12'">
            <div
              :class="{
                'has-error': errors.has(`addEditValidation.date`),
              }"
            >
              <label>{{ $t("Date") }}</label>
              <el-date-picker
                style="width: 100%"
                v-model="filter.date"
                type="date"
                class="mt-2"
                name="date"
                data-vv-scope="addEditValidation"
                v-validate="'required'"
                :data-vv-as="$t('Date')"
                hide-details
                dense
                outlined
              >
              </el-date-picker>
            </div>

            <div class="help-block" v-if="errors.has(`addEditValidation.date`)">
              {{ errors.first(`addEditValidation.date`) }}
            </div>
          </v-col>

          <v-col
            v-if="userDriver && userDriver.admin && !userDriver.admin.city_id"
            class="py-0"
            :cols="$vuetify.breakpoint.mdAndUp ? '2' : '12'"
          >
            <div>
              <label>{{ $t("Area") }}</label>
              <v-autocomplete
                class="d-block my-2"
                name="period_id"
                data-vv-scope="addEditValidation"
                v-validate="''"
                :data-vv-as="$t('Area')"
                hide-details
                dense
                outlined
                v-model="filter.city_id"
                item-value="id"
                item-text="name"
                :items="cityList"
                clearable
              ></v-autocomplete>
            </div>
          </v-col>

          <v-col
            v-if="userDriver && userDriver.admin && !userDriver.admin.city_id"
            class="py-0"
            :cols="$vuetify.breakpoint.mdAndUp ? '2' : '12'"
          >
            <div>
              <label>{{ $t("TripStatus") }}</label>
              <v-autocomplete
                class="d-block my-2"
                name="trip_status"
                data-vv-scope="addEditValidation"
                v-validate="''"
                :data-vv-as="$t('TripStatus')"
                hide-details
                dense
                outlined
                v-model="filter.trip_status"
                item-value="id"
                item-text="name"
                :items="tripStatusList"
                clearable
              ></v-autocomplete>
            </div>
          </v-col>

        </v-row>
        <v-row>

             <v-col
            v-if="userDriver && userDriver.admin && !userDriver.admin.city_id"
            class="py-0"
            :cols="$vuetify.breakpoint.mdAndUp ? '2' : '12'"
          >
            <div>
              <label>{{ $t("OrderType") }}</label>
              <v-autocomplete
                class="d-block my-2"
                name="trip_status"
                data-vv-scope="addEditValidation"
                v-validate="''"
                :data-vv-as="$t('OrderType')"
                hide-details
                dense
                outlined
                v-model="filter.order_type"
                item-value="id"
                item-text="name"
                :items="orderTypeList"
                @change="filter.operator=null;filter.delivery_all_count_value=null;filter.not_delivery_count_value=null;"
                clearable
              ></v-autocomplete>
            </div>
          </v-col>

             <v-col
            v-if="userDriver && userDriver.admin && !userDriver.admin.city_id && filter.order_type"
            class="py-0"
            :cols="$vuetify.breakpoint.mdAndUp ? '2' : '12'"
          >
            <div>
              <label>{{ $t("Operator") }}</label>
              <v-autocomplete
                class="d-block my-2"
                name="trip_status"
                data-vv-scope="addEditValidation"
                v-validate="''"
                :data-vv-as="$t('Operator')"
                hide-details
                dense
                outlined
                v-model="filter.operator"
                item-value="id"
                item-text="name"
                :items="operatorList"
                clearable
              ></v-autocomplete>
            </div>
          </v-col>

          <v-col
            v-if="userDriver && userDriver.admin && !userDriver.admin.city_id & filter.order_type == 1"
            class="py-0"
            :cols="$vuetify.breakpoint.mdAndUp ? '2' : '12'"
          >
            <div>
              <label>{{ $t("Value") }}</label>
              <v-text-field
                class="d-block my-2"
                name="trip_status"
                data-vv-scope="addEditValidation"
                v-validate="''"
                :data-vv-as="$t('Value')"
                hide-details
                dense
                outlined
                v-model="filter.delivery_all_count_value"
              ></v-text-field>
            </div>
          </v-col>

          <v-col
            v-if="userDriver && userDriver.admin && !userDriver.admin.city_id & filter.order_type == 2"
            class="py-0"
            :cols="$vuetify.breakpoint.mdAndUp ? '2' : '12'"
          >
            <div>
              <label>{{ $t("Value") }}</label>
              <v-text-field
                class="d-block my-2"
                name="trip_status"
                data-vv-scope="addEditValidation"
                v-validate="''"
                :data-vv-as="$t('Value')"
                hide-details
                dense
                outlined
                v-model="filter.not_delivery_count_value"
              ></v-text-field>
            </div>
          </v-col>

            <v-col
            v-if="userDriver && userDriver.admin && !userDriver.admin.city_id"
            class="py-0"
            :cols="$vuetify.breakpoint.mdAndUp ? '2' : '12'"
          >
            <div>
              <label>{{ $t("TripType") }}</label>
              <v-autocomplete
                class="d-block my-2"
                name="trip_status"
                data-vv-scope="addEditValidation"
                v-validate="''"
                :data-vv-as="$t('TripType')"
                hide-details
                dense
                outlined
                v-model="filter.trip_type"
                item-value="id"
                item-text="name"
                :items="tripTypeList"
                @change="
                filter.trip_operator=null;
                filter.start_trip_value=null;
                filter.end_trip_value=null;
                filter.first_time_order_value=null;
                filter.last_time_order_value=null;
                filter.format_all_time=null;
                "
                clearable
              ></v-autocomplete>
            </div>
          </v-col>

          <v-col
            v-if="userDriver && userDriver.admin && !userDriver.admin.city_id && filter.trip_type"
            class="py-0"
            :cols="$vuetify.breakpoint.mdAndUp ? '2' : '12'"
          >
            <div>
              <label>{{ $t("Operator") }}</label>
              <v-autocomplete
                class="d-block my-2"
                name="trip_status"
                data-vv-scope="addEditValidation"
                v-validate="''"
                :data-vv-as="$t('Operator')"
                hide-details
                dense
                outlined
                v-model="filter.trip_operator"
                item-value="id"
                item-text="name"
                :items="operatorList"
                clearable
              ></v-autocomplete>
            </div>
          </v-col>

          <v-col
            v-if="userDriver && userDriver.admin && !userDriver.admin.city_id & filter.trip_type == 1"
            class="py-0"
            :cols="$vuetify.breakpoint.mdAndUp ? '2' : '12'"
          >
            <div>
              <label>{{ $t("Value") }}</label>
              <v-text-field
                class="d-block my-2"
                name="trip_status"
                data-vv-scope="addEditValidation"
                v-validate="''"
                :data-vv-as="$t('Value')"
                hide-details
                dense
                outlined
                v-model="filter.start_trip_value"
              ></v-text-field>
            </div>
          </v-col>

          <v-col
            v-if="userDriver && userDriver.admin && !userDriver.admin.city_id & filter.trip_type == 2"
            class="py-0"
            :cols="$vuetify.breakpoint.mdAndUp ? '2' : '12'"
          >
            <div>
              <label>{{ $t("Value") }}</label>
              <v-text-field
                class="d-block my-2"
                name="trip_status"
                data-vv-scope="addEditValidation"
                v-validate="''"
                :data-vv-as="$t('Value')"
                hide-details
                dense
                outlined
                v-model="filter.first_time_order_value"
              ></v-text-field>
            </div>
          </v-col>

          <v-col
            v-if="userDriver && userDriver.admin && !userDriver.admin.city_id & filter.trip_type == 3"
            class="py-0"
            :cols="$vuetify.breakpoint.mdAndUp ? '2' : '12'"
          >
            <div>
              <label>{{ $t("Value") }}</label>
              <v-text-field
                class="d-block my-2"
                name="trip_status"
                data-vv-scope="addEditValidation"
                v-validate="''"
                :data-vv-as="$t('Value')"
                hide-details
                dense
                outlined
                v-model="filter.last_time_order_value"
              ></v-text-field>
            </div>
          </v-col>

            <v-col
            v-if="userDriver && userDriver.admin && !userDriver.admin.city_id & filter.trip_type == 4"
            class="py-0"
            :cols="$vuetify.breakpoint.mdAndUp ? '2' : '12'"
          >
            <div>
              <label>{{ $t("Value") }}</label>
              <v-text-field
                class="d-block my-2"
                name="trip_status"
                data-vv-scope="addEditValidation"
                v-validate="''"
                :data-vv-as="$t('Value')"
                hide-details
                dense
                outlined
                v-model="filter.end_trip_value"
              ></v-text-field>
            </div>
          </v-col>

          <v-col
            v-if="userDriver && userDriver.admin && !userDriver.admin.city_id & filter.trip_type == 5"
            class="py-0"
            :cols="$vuetify.breakpoint.mdAndUp ? '2' : '12'"
          >
            <div>
              <label>{{ $t("Value") }}</label>
              <v-text-field
                class="d-block my-2"
                name="trip_status"
                data-vv-scope="addEditValidation"
                v-validate="''"
                :data-vv-as="$t('Value')"
                hide-details
                dense
                outlined
                v-model="filter.format_all_time"
              ></v-text-field>
            </div>
          </v-col>

          <v-col
            v-if="userDriver && userDriver.admin && userDriver.admin.city_id"
            class="py-7"
            :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'"
          >
            <el-button
              @click="filterData"
              style="width: 120px; background: #000; color: #fff"
              >{{ $t("Filter") }}</el-button
            >
            <el-button
              v-if="
                details &&
                details.current_trip == null &&
                details.trip_btn &&
                check_approved
              "
              @click="openTripModal"
              class="mx-2"
              style="width: 120px; background: green; color: #fff"
            >
              انطلاق
            </el-button>

            <el-button
              v-if="
                details &&
                details.trips.length > 0 &&
                details.current_trip &&
                details.current_trip.status == 5 &&
                details.trip_btn &&
                check_approved
              "
              @click="endTripModal = true"
              class="mx-2"
              style="width: 120px; background: red; color: #fff"
            >
              إنهاء الرحلة
            </el-button>
          </v-col>

          <v-col
            v-else
            class="py-7"
            :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'"
          >
            <el-button
              @click="filterAdminData2"
              style="width: 120px; background: #000; color: #fff"
              class="mx-2"
              >{{ $t("Filter") }}</el-button
            >

            <el-button
              @click="filterAdminData"
              style="width: 120px; background: #10aaba; color: #fff"
              class="mx-2"
              >{{ $t("FilterAllData") }}</el-button
            >
          </v-col>
        </v-row>

        <v-row>
          <v-col :cols="$vuetify.breakpoint.mdAndUp ? '2' : '12'">
            <v-alert
              v-if="
                details &&
                details.trips.length > 0 &&
                details.current_trip &&
                details.current_trip.status == 6
              "
              border="right"
              color="pink"
              style="color: #fff"
            >
              الرحلة منتهية
            </v-alert>
          </v-col>
        </v-row>

        <div v-loading="loading">
          <el-card v-if="adminDetails">
            <h2>الأحداث الكل</h2>

            <div
              v-if="adminDetails.length > 0"
              class="mt-5"
              style="
                width: 100%;
                overflow-x: auto;
                direction: rtl !important;
              "
            >
              <div
                style="text-align: center"
                :style="
                  $vuetify.breakpoint.mdAndUp ? 'white-space: nowrap' : ''
                "
              >
                <div
                  v-for="(ad, adKey) in adminDetails"
                  :key="adKey"
                  class="adminEventsDiv"
                  :style="
                    $vuetify.breakpoint.mdAndUp
                      ? 'display: inline-block;width: 360px;margin-left: 80px;'
                      : ''
                  "
                >

                 <div class="row">
                          <div
                            class="col-md-11 area_div_block"
                            :style="setBG(ad.trip.id)"
                            style="
                              font-weight: bold;
                              border: 6px solid #797979;
                              border-radius: 10px;
                            "
                            @dblclick="goToEventDetails(ad.id)"
                          >
                          <v-row>
                          <v-col md="12" style="margin-top:10px;">
                              <h3>
                                {{ ad.city_name }} - {{ ad.driver_name }}
                              </h3>
                              <h3 class="mt-2" style="color: red" v-if="ad.trip">
                                {{ ad.trip.text }}
                              </h3>
                            </v-col>

                             <v-col md="6" style="margin: 0;padding: 0;">
                              <p style="margin-bottom:5px;"> وقت الانطلاق </p>
                              <p style="margin-bottom:5px;"> {{ad.trip.start_trip?ad.trip.start_trip: '-'}}</p>
                            </v-col>

                            <v-col md="6" style="margin: 0;padding: 0;">
                              <p style="margin-bottom:5px;">وقت أول طلب</p>
                              <p style="margin-bottom:5px;">{{ad.trip.first_time_order?ad.trip.first_time_order:'-'}}</p>
                            </v-col>

                            <v-col md="6" style="margin: 0;padding: 0;">
                              <p style="margin-bottom:5px;"> وقت آخر طلب </p>
                              <p style="margin-bottom:5px;"> {{ad.trip.last_time_order?ad.trip.last_time_order:'-'}}</p>
                            </v-col>

                            <v-col md="6" style="margin: 0;padding: 0;">
                              <p style="margin-bottom:5px;">وقت العودة</p>
                              <p style="margin-bottom:5px;">{{ad.trip.end_trip?ad.trip.end_trip:'-'}}</p>
                            </v-col>

                            <v-col md="12" style="margin: 0;padding: 0;">
                              <p style="margin-bottom:5px;"> {{ad.trip.all_time?ad.trip.all_time:'-'}}</p>
                            </v-col>

                              <v-col md="12" style="margin: 0;padding: 0;margin-bottom:15px;">
                                 <div>
                            <span>{{ ad.delivery_count?ad.delivery_count:0 }}</span>

                            <v-icon
                              class="my-0 mr-2 ml-2"
                              style="font-size: 28px; color: green !important"
                              dense
                              >mdi-checkbox-marked-circle-outline</v-icon
                            >

                            <span>{{ ad.problem_delivery_count?ad.problem_delivery_count: 0 }}</span>
                            <v-icon
                              class="mx-1"
                              style="font-size: 28px; color: red !important"
                              dense
                              >mdi-close-circle-outline</v-icon
                            >

                            <span>{{ ad.not_delivery_count?ad.not_delivery_count: 0 }}</span>
                            <v-icon
                              class="my-0 mr-2 ml-2"
                              style="font-size: 28px; color: purple !important"
                              dense
                              >mdi-circle-outline</v-icon
                            >
                          </div>
                              </v-col>

                          </v-row>

                          
                          </div>
                        </div>

                  <table class="custom-driver-table">
                    <thead>
                      <tr>
                        <th style="cursor:pointer;" @click="sortBy('subscription_id')">#
                          <span v-if="sortKey === 'subscription_id' && !reverseSortOrder">&#x25B2;</span>
                          <span v-if="sortKey === 'subscription_id' && reverseSortOrder">&#x25BC;</span>
                        </th>
                        <th style="cursor:pointer;" @click="sortBy('customer_name')">الاسم
                          <span v-if="sortKey === 'customer_name' && !reverseSortOrder">&#x25B2;</span>
                          <span v-if="sortKey === 'customer_name' && reverseSortOrder">&#x25BC;</span>
                        </th>
                        <th style="cursor:pointer;" @click="sortBy('delivery_time')">وقت التسليم
                          <span v-if="sortKey === 'delivery_time' && !reverseSortOrder">&#x25B2;</span>
                          <span v-if="sortKey === 'delivery_time' && reverseSortOrder">&#x25BC;</span>
                        </th>
                        <th style="cursor:pointer;" @click="sortBy('driver_delivery')">توصيل
                          <span v-if="sortKey === 'driver_delivery' && !reverseSortOrder">&#x25B2;</span>
                          <span v-if="sortKey === 'driver_delivery' && reverseSortOrder">&#x25BC;</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                     <tr v-for="(ad, adkey) in sortFun(ad.subscription_data)" :key="adkey">
                    <td>{{ parseInt(adkey) + 1 }}</td>
                    <td>
                      <a
                        @click="openDetailsModal(ad)"
                        style="
                          color: blue !important;
                          text-decoration: none;
                          cursor: pointer;
                        "
                      >{{ ad.customer_name }}</a>
                    </td>
                    <td>
                      <span
                      >{{ ad.delivery_time }}</span>
                    </td>
                    <td>
                      <div v-if="ad.driver_delivery === 2">
                        <v-icon
                          class="mx-2"
                          style="font-size: 18px; color: red !important"
                          dense
                        >mdi-close-circle-outline</v-icon>
                      </div>
                      <div v-else-if="ad.driver_delivery === 1">
                        <v-icon
                          class="mx-2"
                          style="font-size: 18px; color: green !important"
                          dense
                        >mdi-checkbox-marked-circle-outline</v-icon>
                      </div>
                      <div v-else>
                        <v-icon
                          class="mx-2"
                          style="font-size: 18px; color: purple !important"
                          dense
                        >mdi-circle-outline</v-icon>
                      </div>
                    </td>
                  </tr>

                    </tbody>
                  </table>

                </div>
              </div>
            </div>

            <div v-else>
              <p class="text-center" style="color:red;font-weight:bol;d">
                لا توجد بيانات
              </p>
            </div>
          </el-card>

          <el-card v-if="adminDetails2">
            <h2>الأحداث</h2>

            <div
              v-if="adminDetails2.length > 0"
              class="mt-5"
            >
              <div
                style="text-align: center"
                :style="
                  $vuetify.breakpoint.mdAndUp ? 'white-space: nowrap' : ''
                "
              >
                <v-btn
                  @click="showType = 2"
                  :color="showType == 2 ? 'success' : ''"
                  :text="showType != 2"
                  :style="showType == 2 ? 'color:#fff' : 'color:#000'"
                  >العرض مربعات</v-btn
                >
                <v-btn
                  @click="showType = 3"
                  :color="showType == 3 ? 'success' : ''"
                  :text="showType != 3"
                  :style="showType == 3 ? 'color:#fff' : 'color:#000'"
                  >العرض جدول</v-btn
                >

                <div v-if="showType == 1">
                  <v-container fluid>
                    <v-row>
                      <v-col
                        v-for="(ad, adKey) in adminDetails2"
                        :key="adKey"
                        cols="12"
                        md="3"
                      >
                        <div
                          class="area_div_block"
                          :style="setBG(ad.trip.id)"
                          style="
                            font-weight: bold;
                            border: 6px solid #797979;
                            border-radius: 10px;
                          "
                          @dblclick="goToEventDetails(ad.id)"
                        >
                          <h3 class="my-2">{{ ad.city_name }}</h3>
                          <p class="my-2">السائق: {{ ad.driver_name }}</p>
                          <p class="my-2">
                            حالة الرحلة:
                            <span v-if="ad.trip">{{ ad.trip.text }}</span>
                          </p>
                          <p class="my-2">
                            تم التسليم: {{ ad.delivery_count }}
                          </p>
                          <p class="my-2">
                            لم يتم التسليم: {{ ad.problem_delivery_count }}
                          </p>
                          <p class="my-2">
                            جاري التسليم : {{ ad.not_delivery_count }}
                          </p>
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </div>

                <div v-else-if="showType == 2">
                  <v-container fluid v-if="$vuetify.breakpoint.mdAndUp ">
                    <v-row>
                      <v-col
                        v-for="(ad, adKey) in adminDetails2"
                        :key="adKey"
                        cols="12"
                        md="3"
                      >
                        <div class="row">
                          <div
                            class="col-md-11 area_div_block"
                            :style="setBG(ad.trip.id)"
                            style="
                              font-weight: bold;
                              border: 6px solid #797979;
                              border-radius: 10px;
                            "
                            @dblclick="goToEventDetails(ad.id)"
                          >
                          <v-row>
                          <v-col md="12" style="margin-top:10px;">
                              <h3>
                                {{ ad.city_name }} - {{ ad.driver_name }}
                              </h3>
                              <h3 class="mt-2" style="color: red" v-if="ad.trip">
                                {{ ad.trip.text }}
                              </h3>
                            </v-col>

                             <v-col md="6" style="margin: 0;padding: 0;">
                              <p style="margin-bottom:5px;"> وقت الانطلاق </p>
                              <p style="margin-bottom:5px;"> {{ad.trip.start_trip?ad.trip.start_trip: '-'}}</p>
                            </v-col>

                            <v-col md="6" style="margin: 0;padding: 0;">
                              <p style="margin-bottom:5px;">وقت أول طلب</p>
                              <p style="margin-bottom:5px;">{{ad.trip.first_time_order?ad.trip.first_time_order:'-'}}</p>
                            </v-col>

                            <v-col md="6" style="margin: 0;padding: 0;">
                              <p style="margin-bottom:5px;"> وقت آخر طلب </p>
                              <p style="margin-bottom:5px;"> {{ad.trip.last_time_order?ad.trip.last_time_order:'-'}}</p>
                            </v-col>

                            <v-col md="6" style="margin: 0;padding: 0;">
                              <p style="margin-bottom:5px;">وقت العودة</p>
                              <p style="margin-bottom:5px;">{{ad.trip.end_trip?ad.trip.end_trip:'-'}}</p>
                            </v-col>

                            <v-col md="12" style="margin: 0;padding: 0;">
                              <p style="margin-bottom:5px;"> {{ad.trip.all_time?ad.trip.all_time:'-'}}</p>
                            </v-col>

                              <v-col md="12" style="margin: 0;padding: 0;margin-bottom:15px;">
                                 <div>
                            <span>{{ ad.delivery_count?ad.delivery_count:0 }}</span>

                            <v-icon
                              class="my-0 mr-2 ml-2"
                              style="font-size: 28px; color: green !important"
                              dense
                              >mdi-checkbox-marked-circle-outline</v-icon
                            >

                            <span>{{ ad.problem_delivery_count?ad.problem_delivery_count: 0 }}</span>
                            <v-icon
                              class="mx-1"
                              style="font-size: 28px; color: red !important"
                              dense
                              >mdi-close-circle-outline</v-icon
                            >

                            <span>{{ ad.not_delivery_count?ad.not_delivery_count: 0 }}</span>
                            <v-icon
                              class="my-0 mr-2 ml-2"
                              style="font-size: 28px; color: purple !important"
                              dense
                              >mdi-circle-outline</v-icon
                            >
                          </div>
                              </v-col>

                          </v-row>

                          
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>

                   <v-container fluid v-else>
  <v-row>
    <v-col
      v-for="(ad, adKey) in adminDetails2"
      :key="adKey"
      cols="12"
      xs="12"
      md="6"
    >
      <div  @dblclick="goToEventDetails(ad.id)" 
      style=" font-weight: bold;
                            border: 6px solid #797979;
                            border-radius: 10px;padding-top:15px;"
      class="area_div_block" :style="setBG(ad.trip.id)">
        <h3 class="city-driver-name">
          {{ ad.city_name }} - {{ ad.driver_name }}
        </h3>
        <h3 class="mt-2" style="color: red" v-if="ad.trip">
          {{ ad.trip.text }}
        </h3>
        <div class="trip-info mt-2">
          <p>وقت الانطلاق: {{ad.trip.start_trip?ad.trip.start_trip: '-'}}</p>
          <p>وقت أول طلب: {{ad.trip.first_time_order?ad.trip.first_time_order:'-'}}</p>
          <p>وقت آخر طلب: {{ad.trip.last_time_order?ad.trip.last_time_order:'-'}}</p>
          <p>وقت العودة: {{ad.trip.end_trip?ad.trip.end_trip:'-'}}</p>
          <p>الوقت الإجمالي: {{ad.trip.all_time?ad.trip.all_time:'-'}}</p>
        </div>
        <div class="delivery-info" >
          <div style="padding-bottom:15px;">
            <span>{{ ad.delivery_count?ad.delivery_count:0 }}</span>
            <v-icon
              class="my-0 mr-2 ml-2"
              style="font-size: 28px; color: green !important"
              dense
              >mdi-checkbox-marked-circle-outline</v-icon
            >
            <span>{{ ad.problem_delivery_count?ad.problem_delivery_count: 0 }}</span>
            <v-icon
              class="mx-1"
              style="font-size: 28px; color: red !important"
              dense
              >mdi-close-circle-outline</v-icon
            >
            <span>{{ ad.not_delivery_count?ad.not_delivery_count: 0 }}</span>
            <v-icon
              class="my-0 mr-2 ml-2"
              style="font-size: 28px; color: purple !important"
              dense
              >mdi-circle-outline</v-icon
            >
          </div>
        </div>
      </div>
    </v-col>
  </v-row>
</v-container>



                </div>

               <div v-else-if="showType === 3">
  <v-container fluid>
    <table id="eventsTable">
      <thead>
        <tr>
          <th style="cursor:pointer;" @click="sortBy('city_name')">اسم المنطقة
            <span v-if="sortKey === 'city_name' && !reverseSortOrder">&#x25B2;</span>
            <span v-if="sortKey === 'city_name' && reverseSortOrder">&#x25BC;</span>
          </th>
          <th style="cursor:pointer;" @click="sortBy('driver_name')">المندوب
            <span v-if="sortKey === 'driver_name' && !reverseSortOrder">&#x25B2;</span>
            <span v-if="sortKey === 'driver_name' && reverseSortOrder">&#x25BC;</span>
          </th>
          <th style="cursor:pointer;" @click="sortBy('trip_id')">حالة الرحلة
            <span v-if="sortKey === 'trip_id' && !reverseSortOrder">&#x25B2;</span>
            <span v-if="sortKey === 'trip_id' && reverseSortOrder">&#x25BC;</span>
          </th>
          <th style="cursor:pointer;" @click="sortBy('delivery_all_count')">عدد الطلبات
            <span v-if="sortKey === 'delivery_all_count' && !reverseSortOrder">&#x25B2;</span>
            <span v-if="sortKey === 'delivery_all_count' && reverseSortOrder">&#x25BC;</span>
          </th>
          <th style="cursor:pointer;" @click="sortBy('not_delivery_count')">الطلبات المتبقية
            <span v-if="sortKey === 'not_delivery_count' && !reverseSortOrder">&#x25B2;</span>
            <span v-if="sortKey === 'not_delivery_count' && reverseSortOrder">&#x25BC;</span>
          </th>
          <th style="cursor:pointer;" @click="sortBy('trip_start_trip_order')">وقت الانطلاق
            <span v-if="sortKey === 'trip_start_trip_order' && !reverseSortOrder">&#x25B2;</span>
            <span v-if="sortKey === 'trip_start_trip_order' && reverseSortOrder">&#x25BC;</span>
          </th>
          <th style="cursor:pointer;" @click="sortBy('trip_first_time_order_unix')">وقت أول طلب
            <span v-if="sortKey === 'trip_first_time_order_unix' && !reverseSortOrder">&#x25B2;</span>
            <span v-if="sortKey === 'trip_first_time_order_unix' && reverseSortOrder">&#x25BC;</span>
          </th>
           <th style="cursor:pointer;" @click="sortBy('trip_last_time_order_unix')">وقت آخر طلب
            <span v-if="sortKey === 'trip_last_time_order_unix' && !reverseSortOrder">&#x25B2;</span>
            <span v-if="sortKey === 'trip_last_time_order_unix' && reverseSortOrder">&#x25BC;</span>
          </th>
           <th style="cursor:pointer;" @click="sortBy('trip_end_trip_order')">وقت العودة
            <span v-if="sortKey === 'trip_end_trip_order' && !reverseSortOrder">&#x25B2;</span>
            <span v-if="sortKey === 'trip_end_trip_order' && reverseSortOrder">&#x25BC;</span>
          </th>

           <th style="cursor:pointer;" @click="sortBy('trip_all_time_order')">الوقت الكلي
            <span v-if="sortKey === 'trip_all_time_order' && !reverseSortOrder">&#x25B2;</span>
            <span v-if="sortKey === 'trip_all_time_order' && reverseSortOrder">&#x25BC;</span>
          </th>

        </tr>
      </thead>
      <tbody>
        <tr v-for="(ad, adKey) in sortFun(adminDetails2)" :key="adKey" cols="12" md="3" @dblclick="goToEventDetails(ad.id)">
          <td>{{ ad.city_name }}</td>
          <td>{{ ad.driver_name }}</td>
          <td :style="setBG2(ad.trip_id)">{{ ad.trip.text }}</td>
          <td>{{ ad.delivery_all_count }}</td>
          <td>{{ ad.not_delivery_count ? ad.not_delivery_count : 0 }}</td>
          <td>{{ ad.trip_start_trip }}</td>
          <td>{{ ad.trip_first_time_order }}</td>
          <td>{{ ad.trip_last_time_order }}</td>
          <td>{{ ad.trip_end_trip }}</td>
          <td>{{ getFormatHoursMinutes(ad.trip.all_time) }}</td>
        </tr>
      </tbody>
    </table>
  </v-container>
</div>

              </div>
            </div>

            <div v-else>
              <p class="text-center" style="color:red;font-weight:bol;d">
                لا توجد بيانات
              </p>
            </div>
          </el-card>

          <el-card
            v-if="details"
            style="border: 1px solid blue; margin-top: 15px"
          >
            <v-container fluid>
              <v-row>
                <v-col :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'">
                  <h3>{{ $t("City") }}: {{ details.city_name }}</h3>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'">
                  <h3>{{ $t("Period") }}: {{ details.period_name }}</h3>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'">
                  <h3>تم التسليم : {{ details.deliver }}</h3>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'">
                  <h3>لم يتم التسليم : {{ details.not_deliver }}</h3>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'">
                  <h3>اسم المندوب: {{ details.driver_name }}</h3>
                </v-col>

                <v-col :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'">
                  <h3>رقم الجوال: {{ details.driver_mobile }}</h3>
                </v-col>

                <v-col
                  class="py-0"
                  ::cols="$vuetify.breakpoint.mdAndUp?'6': '12'"
                >
                  <div
                    :class="{
                      'has-error': errors.has(
                        `addEditValidation2.neighborhood_id`
                      ),
                    }"
                  >
                    <v-autocomplete
                      style="width: 200px"
                      class="d-block my-2"
                      name="neighborhood_id"
                      data-vv-scope="addEditValidation2"
                      v-validate="'required'"
                      :data-vv-as="$t('Neighborhood')"
                      hide-details
                      dense
                      outlined
                      :placeholder="$t('Neighborhood')"
                      v-model="details.neighborhood_id"
                      item-value="id"
                      item-text="name"
                      :items="details.branches"
                      clearable
                    ></v-autocomplete>
                  </div>

                  <div
                    class="help-block"
                    v-if="errors.has(`addEditValidation2.neighborhood_id`)"
                  >
                    {{ errors.first(`addEditValidation2.neighborhood_id`) }}
                  </div>
                </v-col>

                <v-col :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'">
                  <h3>{{ details.count_subscription }} مشترك</h3>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <el-button
                    v-if="check_approved"
                    :class="$vuetify.breakpoint.mdAndUp ? '' : 'mx-0 my-2'"
                    @click="goTo('driverEvents')"
                    type="primary"
                    >عرض المشتركين بالنسبة للحي</el-button
                  >
                  <el-button
                    v-if="check_approved"
                    :class="
                      $vuetify.breakpoint.mdAndUp ? 'mx-2 my-2' : 'mx-0 my-2'
                    "
                    @click="goToAll('driverEvents2')"
                    type="success"
                    >عرض جميع المشتركين</el-button
                  >

                  <el-button
                    @click="goTo('sortEvents')"
                    type="danger"
                    style="background: purple; border: 1px solid purple"
                    :class="
                      $vuetify.breakpoint.mdAndUp ? 'mx-2 my-2' : 'mx-0 my-2'
                    "
                    >ترتيب المشتركين بالنسبة للحي</el-button
                  >

                  <el-button
                    @click="goToAll('sortEvents2')"
                    type="danger"
                    :class="
                      $vuetify.breakpoint.mdAndUp ? 'mx-2 my-2' : 'mx-0 my-2'
                    "
                    >ترتيب جميع المشتركين</el-button
                  >

                    <el-button
                    @click="goTo('checkedEvents')"
                    type="brown"
                    style="background:brown;color:#fff;"
                    :class="
                      $vuetify.breakpoint.mdAndUp ? 'mx-2 my-2' : 'mx-0 my-2'
                    "
                    >واجهة التشييك بالنسبة للحي</el-button
                  >

                     <el-button
                    @click="goToAll('checkedEvents2')"
                    type="red"
                    style="background:red;color:#fff;"
                    :class="
                      $vuetify.breakpoint.mdAndUp ? 'mx-2 my-2' : 'mx-0 my-2'
                    "
                    >واجهة التشييك لجميع المشتركين</el-button
                  >
                </v-col>
              </v-row>
            </v-container>
          </el-card>
        </div>
      </v-container>
    </v-card-text>

    <v-dialog v-model="delivarDetailsModal" width="80rem">
      <v-card v-if="delivarDetailsData">
        <v-toolbar style="background: blue !important" dense flat>
          <v-toolbar-title class="text-body-2 font-weight-bold white-clr">
            <span
              >تفاصيل تسليم العميل:
              <span>{{ delivarDetailsData.customer_name }}</span></span
            >
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="py-0 pa-4 pt-4 black--text">
          <v-row class="my-2">
            <v-col :cols="$vuetify.breakpoint.mdAndUp ? '3' : '12'">
              اسم الحي: {{ delivarDetailsData.branch_name }}
            </v-col>
            <v-col :cols="$vuetify.breakpoint.mdAndUp ? '3' : '12'">
              وقت التسليم: <span>{{ delivarDetailsData.delivery_time }}</span>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.mdAndUp ? '3' : '12'">
              طريقة التسليم: <span>{{ delivarDetailsData.notes }}</span>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.mdAndUp ? '3' : '12'">
              سبب إلغاء التسليم:
              <span> {{ delivarDetailsData.delete_note_delivery }}</span>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="tripModal" width="80rem">
      <v-card>
        <v-toolbar style="background: green !important" dense flat>
          <v-toolbar-title class="text-body-2 font-weight-bold white-clr">
            تأكيد الانطلاق
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="py-0 pa-4 pt-4 black--text">
          <h3 style="color: blue; text-align: center; font-weight: bold">
            <span>هل أنت متأكد من عملية بدء الرحلة؟</span>
          </h3>

          <v-row class="my-2">
            <v-col
              class="py-0"
              :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'"
            >
              <div>
                <label>صورة درجة الحرارة</label>

                <v-file-input
                  :class="{
                    'custom-error': errors.has(`addEditValidation.temperature`),
                  }"
                  style="display: block; position: relative; top: 10px"
                  dense
                  class="my-0 mx-1"
                  prepend-icon=""
                  placeholder="اضغط هنا لرفع صورة درجة الحرارة"
                  accept="image/*"
                  v-model="tripModalDetails.temperature"
                  data-vv-scope="addEditValidation"
                  v-validate="'required'"
                  name="temperature"
                  outlined
                  :data-vv-as="'صورة درجة الحرارة'"
                ></v-file-input>
              </div>

              <div
                class="help-block"
                v-if="errors.has(`addEditValidation.temperature`)"
              >
                {{ errors.first(`addEditValidation.temperature`) }}
              </div>
            </v-col>
            <v-col
              class="py-0"
              :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'"
            >
              <div
                :class="{
                  'has-error': errors.has(
                    `addEditValidation.temperature_percent`
                  ),
                }"
              >
                <label>درجة الحرارة</label>
                <v-text-field
                  class="d-block my-2"
                  name="temperature_percent"
                  data-vv-scope="addEditValidation"
                  v-validate="'required|decimal'"
                  :data-vv-as="'درجة الحرارة'"
                  hide-details
                  dense
                  outlined
                  v-model.number="tripModalDetails.temperature_percent"
                >
                </v-text-field>
              </div>

              <div
                class="help-block"
                v-if="errors.has(`addEditValidation.temperature_percent`)"
              >
                {{ errors.first(`addEditValidation.temperature_percent`) }}
              </div>
            </v-col>

            <v-col
              class="py-0"
              :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'"
            >
              <div
                :class="{
                  'has-error': errors.has(
                    `addEditValidation.count_subscriptions`
                  ),
                }"
              >
                <label>عدد الطلبات المستلمة</label>
                <v-text-field
                  class="d-block my-2"
                  name="count_subscriptions"
                  data-vv-scope="addEditValidation"
                  v-validate="'required|numeric'"
                  :data-vv-as="'عدد الطلبات المستلمة'"
                  hide-details
                  dense
                  outlined
                  v-model.number="tripModalDetails.count_subscriptions"
                >
                </v-text-field>
              </div>

              <div
                class="help-block"
                v-if="errors.has(`addEditValidation.count_subscriptions`)"
              >
                {{ errors.first(`addEditValidation.count_subscriptions`) }}
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="danger"
            text
            small
            class="body-2 font-weight-bold"
            @click.native="tripModal = false"
            >{{ $t("Cancel") }}</v-btn
          >
          <v-btn
            color="success"
            class="body-2 font-weight-bold"
            outlined
            small
            @click.native="tripStart()"
          >
            {{ $t("Ok") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="endTripModal" width="30rem">
      <v-card>
        <v-toolbar style="background: red !important" dense flat>
          <v-toolbar-title class="text-body-2 font-weight-bold white-clr">
            {{ $t("Confirm") }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="py-0 pa-4 pt-4 black--text">
          <h3 class="clr-primary">هل أنت متأكد من عملية إنهاء الرحلة</h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="danger"
            text
            small
            class="body-2 font-weight-bold"
            @click.native="endTripModal = false"
            >{{ $t("Cancel") }}</v-btn
          >
          <v-btn
            color="success"
            class="body-2 font-weight-bold"
            outlined
            small
            @click.native="tripEnd()"
          >
            {{ $t("Ok") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <template v-if="details && details.trips && check_approved">
      <h2 class="my-2">الرحلات</h2>

      <v-container v-if="false" fluid>
        <v-row>
          <v-col class="py-0" :cols="$vuetify.breakpoint.mdAndUp ? '2' : '12'">
            <div>
              <label>{{ $t("FromDate") }}</label>
              <el-date-picker
                style="width: 100%"
                v-model="filterTrip.from_date"
                type="date"
                class="mt-2"
                name="from_date"
                data-vv-scope="addEditValidation"
                v-validate="''"
                :data-vv-as="$t('FromDate')"
                hide-details
                dense
                outlined
              >
              </el-date-picker>
            </div>
          </v-col>

          <v-col class="py-0" :cols="$vuetify.breakpoint.mdAndUp ? '2' : '12'">
            <div>
              <label>{{ $t("ToDate") }}</label>
              <el-date-picker
                style="width: 100%"
                v-model="filterTrip.from_date"
                type="date"
                class="mt-2"
                name="to_date"
                data-vv-scope="addEditValidation"
                v-validate="''"
                :data-vv-as="$t('ToDate')"
                hide-details
                dense
                outlined
              >
              </el-date-picker>
            </div>
          </v-col>

          <v-col class="py-7" ::cols="$vuetify.breakpoint.mdAndUp?'4': '12'">
            <el-button
              @click="filterTripData"
              style="width: 120px; background: #000; color: #fff"
              class="mx-2"
              >{{ $t("Filter") }}</el-button
            >
          </v-col>
        </v-row>
      </v-container>

      <!-- Start DataTable -->
      <v-data-table
        :headers="table.headers"
        :items="details.trips"
        hide-default-footer
        class="elevation-1"
        :items-per-page="details.trips.length"
        :loading-text="$t('LoadData')"
      >
        <template v-slot:no-data>
          <v-alert
            class="mt-5 white-clr"
            color="third"
            :style="
              $vuetify.breakpoint.mdAndUp ? 'width: 100%;' : 'width: 100vw;'
            "
          >
            {{ $t("NoData") }}
          </v-alert>
        </template>

        <template v-slot:item.temperature="{ item }">
          <a
            :href="item.temperature ? item.temperature : '/assets/no-img.png'"
            target="_blank"
          >
            <img
              style="border-radius: 50%; margin: 0 5px; cursor: pointer"
              class="mr-2 mt-2"
              width="50"
              height="50"
              :src="item.temperature ? item.temperature : '/assets/no-img.png'"
              alt=""
            />
          </a>
        </template>
      </v-data-table>

      <!-- End DataTable -->
    </template>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "driverHome",
  data() {
    return {
      operatorList: [
        {
          id: '>=',
          name: 'أكبر من أو يساوي',
        },
        {
          id: '>',
          name: 'أكبر من',
        },
        {
          id: '<=',
          name: 'أصغر من أو يساوي',
        },
        {
          id: '<',
          name: 'أصغر من',
        },
        {
          id: '=',
          name: 'يساوي',
        }
      ],
      tripTypeList: [
        {
          id: 1,
          name: 'حسب وقت الانطلاق'
        },
        {
          id: 2,
          name: 'حسب وقت أول طلب'
        },
        {
          id: 3,
          name: 'حسب وقت آخر طلب'
        },
        {
          id: 4,
          name: 'حسب وقت العودة'
        },
        {
          id: 5,
          name: 'حسب الوقت الكلي'
        }
      ],
      subscriptionData: [], // Your subscription data array
      sortKey: '',
      reverseSortOrder: false,

      showType: 2,
      branches: [],
      tripStatusList: [],
      adminDetails: null,
      adminDetails2: null,
      check_approved: false,
      delivarDetailsModal: false,
      delivarDetailsData: {},
      tripModalDetails: {
        temperature_percent: "",
        count_subscriptions: "",
        temperature: null,
      },
      table: {
        page: 1,
        length: null,
        itemsPerPage: 10,
        loading: false,
        items: [],
        headers: [
          { text: "#", value: "id" },
          { text: "صورة درجة الحرارة ", value: "temperature" },
          { text: "درجة الحرارة ", value: "temperature_percent" },
          { text: "عدد الطلبات المستلمة", value: "count_subscriptions" },
          {
            text: "وقت البداية",
            value: "start_time",
          },
          {
            text: "وقت النهاية",
            value: "end_time",
          },
          {
            text: "الفترة",
            value: "period_name",
          },
          {
            text: "الحالة",
            value: "status_text",
          },
        ],
      },
      userDriver: localStorage.getItem("driver")
        ? JSON.parse(localStorage.getItem("driver"))
        : null,
      timer: null,
      search: null,
      details: null,
      cityTerm: "",
      filter: {
        trip_status: null,
        city_id: null,
        period_id: null,
        date: null,
        order_type: null,
        operator: null,
        delivery_all_count_value: null,
        trip_type: null,
        trip_operator: null,
        start_trip_value: null,
        end_trip_value: null,
        first_time_order_value: null,
        last_time_order_value: null,
        format_all_time: null,
      },
      cityList: [],
      orderTypeList: [
        {
          id: 1,
          name : 'حسب عدد الطلبات',
        },
        {
          id: 2,
          name : 'حسب الطلبات المتبقية',
        }
      ],
      periodList: [],
      loading: false,
      tripModal: false,
      endTripModal: false,
      filterTrip: {
        from_date: null,
        to_date: null,
      },
    };
  },
  methods: {
    sortFun(data) {
    const key = this.sortKey;
    const reverse = this.reverseSortOrder ? -1 : 1;

    const sortedData = data.slice().sort((a, b) => {
      const valueA = key === '#' ? parseInt(a.key) + 1 : a[key];
      const valueB = key === '#' ? parseInt(b.key) + 1 : b[key];

      if (valueA < valueB) {
        return -1 * reverse;
      } else if (valueA > valueB) {
        return 1 * reverse;
      }
      return 0;
    });

    return sortedData;
  },
    sortBy(key) {
      if (this.sortKey === key) {
        this.reverseSortOrder = !this.reverseSortOrder;
      } else {
        this.sortKey = key;
        this.reverseSortOrder = false;
      }
      this.sortedData = this.sortFun(this.ad.subscription_data);
    },
    getFormatHoursMinutes(val) {
      let tripTimeInMinutes = val;
      let hours = Math.floor(tripTimeInMinutes / 60);
      let minutes = tripTimeInMinutes % 60;

      let hoursText = hours > 0 ? `${hours} س ${hours > 1 ? "" : ""} و ` : "";

      let minutesText = minutes > 0 ? ` ${minutes} د ` : "";

      let formattedTripTime = `${hoursText} ${minutesText}`.trim();

      return formattedTripTime;
    },
    setBG(index) {
      if (index == null) index = 1;
      let status = index - 1;
      let clrs = [
        { bg: "#D5D5D5", clr: "#000" },
        { bg: "#B9FFFF", clr: "#000" },
        { bg: "#D8FDC7", clr: "#000" },
        { bg: "#FEAEC9", clr: "#000" },
        { bg: "#C5F65F", clr: "#000" },
        { bg: "#8FFB67", clr: "#000" },
      ];
      return `background:${clrs[status].bg};color:${clrs[status].clr}`;
    },
    setBG2(index) {
      if (index == null) index = 1;
      let status = index - 1;
      let clrs = [
         { bg: "#D5D5D5", clr: "#000" },
        { bg: "#B9FFFF", clr: "#000" },
        { bg: "#D8FDC7", clr: "#000" },
        { bg: "#FEAEC9", clr: "#000" },
        { bg: "#C5F65F", clr: "#000" },
        { bg: "#8FFB67", clr: "#000" },
      ];
      return `background:${clrs[status].bg};color:${clrs[status].clr}`;
    },
    goToEventDetails(id) {
      this.$router.push({ name: "eventDriverDetails", params: { id: id } });
    },
    openDetailsModal(item) {
      this.delivarDetailsModal = true;
      this.delivarDetailsData = item;
    },
    openTripModal() {
      this.tripModal = true;
      this.tripModalDetails = {
        temperature_percent: "",
        count_subscriptions: "",
        temperature: null,
      };
      this.$validator.reset();
    },
    filterTripData() {},

    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },

    tripStart() {
      if (!this.filter.period_id)
        return this.notifyCustomError("خطأ", "الفترة مطلوبة");
      let date = moment(this.filter.date).format("YYYY-MM-DD HH:mm:ss");

      this.$validator.validateAll("addEditValidation").then((valid) => {
        if (!valid) return this.notifyCustomError("Error", "ValidationsErrors");

        // let imageBase64 = this.toBase64(this.tripModalDetails.temperature);
        // let tempImage= '';
        let sendData = {
          date: null,
          period_id: "",
          temperature: null,
          temperature_percent: "",
          count_subscriptions: "",
        };
        // imageBase64.then(res => {
        //   tempImage= res;

        sendData = {
          date: date,
          period_id: this.filter.period_id,
          // temperature:tempImage,
          temperature: this.tripModalDetails.temperature,
          temperature_percent: this.tripModalDetails.temperature_percent,
          count_subscriptions: this.tripModalDetails.count_subscriptions,
        };

        this.$store.dispatch(`driverDetails/addTrip`, sendData).then((res) => {
          // need to change
          this.notifySuccess(`UpdateStatus`, res);
          if (this.tripModal) this.tripModal = false;
          if (this.endTripModal) this.endTripModal = false;
          this.filterData();
        });
      });

      // });
    },
    tripEnd() {
      if (!this.filter.period_id)
        return this.notifyCustomError("خطأ", "الفترة مطلوبة");
      let date = moment().format("YYYY-MM-DD HH:mm:ss");
      let sendData = {
        current_trip: this.details.current_trip
          ? this.details.current_trip.id
          : "",
        date: date,
      };
      this.$store.dispatch(`driverDetails/endTrip`, sendData).then((res) => {
        // need to change
        this.notifySuccess(`UpdateStatus`, res);
        if (this.tripModal) this.tripModal = false;
        if (this.endTripModal) this.endTripModal = false;
        this.filterData();
      });
    },
    serchOnCity(e) {
      let val = e.target.value;
      if (val && val.length > 2) this.serachCity(val);
    },
    serachCity(val) {
      if (val) {
        this.$store.dispatch(`home/listCities`, val).then((res) => {
          this.cityList = res.data;
        });
      }
    },
    getCities() {
      this.$store.dispatch(`home/listCities`).then((res) => {
        this.cityList = res.data;
      });
    },
    getPeriods() {
      this.$store.dispatch(`home/listPeriods`).then((res) => {
        this.periodList = res.data;
      });
    },
    filterData() {
      this.$validator.validateAll("addEditValidation").then((valid) => {
        if (!valid) return this.notifyCustomError("Error", "ValidationsErrors");
        this.loading = true;
        this.details = null;
        let sendData;
        if (this.filter.date)
          sendData = moment(this.filter.date).format("YYYY-MM-DD");

        let send = {
          city_id: this.filter.city_id,
          period_id: this.filter.period_id,
          date: sendData,
        };
        this.$store.dispatch(`home/getDetails`, send).then((res) => {
          this.loading = false;
          if (res.data.check) this.check_approved = true;
          else {
            this.customNotifyError(
              "خطأ",
              "لم يتم الاعتماد لليوم يرحى التواصل مع المشرف"
            );
            this.check_approved = false;
          }
          if (this.filter.city_id)
            localStorage.setItem("home_city_id", this.filter.city_id);
          if (this.filter.date)
            localStorage.setItem("home_date", this.filter.date);
          if (this.filter.period_id)
            localStorage.setItem("home_period_id", this.filter.period_id);
          localStorage.setItem("home_details_neighborhood_id", null);
          this.details = res.data;
        });
      });
    },
    filterAdminData() {
      this.$validator.validateAll("addEditValidation").then((valid) => {
        if (!valid) return this.notifyCustomError("Error", "ValidationsErrors");
        this.loading = true;
        this.adminDetails = null;
        this.adminDetails2 = null;
        let sendData;
        if (this.filter.date)
          sendData = moment(this.filter.date).format("YYYY-MM-DD");

        let send = {
          period_id: this.filter.period_id,
          date: sendData,
        };

        this.$store.dispatch(`home/getAdminDetails`, send).then((res) => {
          localStorage.setItem("home_period_id", send.period_id);
          localStorage.setItem("home_date", send.date);
          if (res.data.check) {
            this.check_approved = true;
            this.adminDetails = res.data.data;
          } else {
            this.check_approved = false;
            this.customNotifyError(
              "خطأ",
              `لم يتم اعتماد التقرير لتاريخ ${moment(this.filter.date).format(
                "YYYY-MM-DD"
              )}`
            );
          }
          this.loading = false;
        });
      });
    },
    filterAdminData2() {
      this.$validator.validateAll("addEditValidation").then((valid) => {
        if (!valid) return this.notifyCustomError("Error", "ValidationsErrors");
        this.loading = true;
        this.adminDetails = null;
        this.adminDetails2 = null;
        let sendData;
        if (this.filter.date)
          sendData = moment(this.filter.date).format("YYYY-MM-DD");

        let send = {
          period_id: this.filter.period_id,
          city_id: this.filter.city_id,
          trip_status: this.filter.trip_status,
          operator: this.filter.operator,
          delivery_all_count_value : this.filter.delivery_all_count_value,
          not_delivery_count_value  : this.filter.not_delivery_count_value,
          trip_operator: this.filter.trip_operator,
          start_trip_value  : this.filter.start_trip_value ,
          end_trip_value  : this.filter.end_trip_value ,
          first_time_order_value  : this.filter.first_time_order_value ,
          last_time_order_value  : this.filter.last_time_order_value ,
          format_all_time  : this.filter.format_all_time ,
          date: sendData,
        };

        this.$store.dispatch(`home/getAdminDetails`, send).then((res) => {
          localStorage.setItem("home_period_id", send.period_id);
          localStorage.setItem("home_date", send.date);
          if (res.data.check) {
            this.check_approved = true;
            this.adminDetails2 = res.data.data.map(x=>{
              if(x.trip){
                 x.trip_id= x.trip.id;
                 x.trip_start_trip_order= x.trip.unix_start_trip;
                 x.trip_end_trip_order= x.trip.unix_end_trip;
                 x.trip_all_time_order= x.trip.all_time;
                 x.trip_first_time_order_unix= x.trip.unix_first_time_order;
                 x.trip_last_time_order_unix= x.trip.unix_last_time_order;

                x.trip_start_trip= x.trip.start_trip;
                 x.trip_end_trip= x.trip.end_trip;
                 x.trip_all_time= x.trip.all_time;
                 x.trip_first_time_order= x.trip.first_time_order;
                 x.trip_last_time_order= x.trip.last_time_order;
           
              }
              return x;
            })
          } else {
            this.check_approved = false;
            this.customNotifyError(
              "خطأ",
              `لم يتم اعتماد التقرير لتاريخ ${moment(this.filter.date).format(
                "YYYY-MM-DD"
              )}`
            );
          }
          this.loading = false;
        });
      });
    },
    goTo(routeName) {
      if (this.filter.city_id)
        localStorage.setItem("home_city_id", this.filter.city_id);
      if (this.filter.date) localStorage.setItem("home_date", this.filter.date);
      if (this.filter.period_id)
        localStorage.setItem("home_period_id", this.filter.period_id);
      if (this.details && this.details.neighborhood_id)
        localStorage.setItem(
          "home_details_neighborhood_id",
          this.details.neighborhood_id
        );
      this.$validator.validateAll("addEditValidation2").then((valid) => {
        if (!valid) return this.notifyCustomError("Error", "ValidationsErrors");

        let sendData;
        if (this.filter.date)
          sendData = moment(this.filter.date).format("YYYY-MM-DD");
        localStorage.setItem("event_date", sendData);

        this.$router.push({
          name: routeName,
          params: {
            id: this.details.neighborhood_id,
            id2: this.details.period_id,
          },
        });
      });
    },
    goToAll(routeName) {
      if (this.filter.city_id)
        localStorage.setItem("home_city_id", this.filter.city_id);
      if (this.filter.date) localStorage.setItem("home_date", this.filter.date);
      if (this.filter.period_id)
        localStorage.setItem("home_period_id", this.filter.period_id);
      if (this.details && this.details.neighborhood_id)
        localStorage.setItem(
          "home_details_neighborhood_id",
          this.details.neighborhood_id
        );

      this.$validator.validateAll("addEditValidation2").then((valid) => {
        let sendData;
        if (this.filter.date)
          sendData = moment(this.filter.date).format("YYYY-MM-DD");
        localStorage.setItem("event_date", sendData);
        this.$router.push({
          name: routeName,
          params: {
            id: this.filter.city_id,
            id2: this.details.period_id,
          },
        });
      });
    },
    getDataFromApi() {
      let options = {
        // page: this.page,
        // branch_id: this.$route.params.id,
        // period_id: this.$route.params.id2,
        // date: localStorage.getItem("event_date"),
      };
      // this.table.loading = true;
      // this.getFrontEndData(`home/getData`, options)
      //   .then((res) => {
      //     // this.table.items = res.data;
      //     // this.table.length = Math.ceil(res.pagination.total / res.pagination.per_page);
      //   })
      //   .finally(() => {
      //     this.table.loading = false;
      // });
    },
    filterData2() {
      this.$validator.validateAll("addEditValidation").then((valid) => {
        if (!valid) return this.notifyCustomError("Error", "ValidationsErrors");
        this.loading = true;
        this.details = null;
        let sendData;
        if (this.filter.date)
          sendData = moment(this.filter.date).format("YYYY-MM-DD");

        let send = {
          city_id: this.filter.city_id,
          period_id: this.filter.period_id,
          date: sendData,
        };
        this.$store.dispatch(`home/getDetails`, send).then((res) => {
          this.loading = false;

          if (res.data.check) this.check_approved = true;
          else {
            this.check_approved = false;
            this.customNotifyError(
              "خطأ",
              "لم يتم الاعتماد لليوم يرحى التواصل مع المشرف"
            );
          }

          this.details = res.data;
          let home_details_neighborhood_id =
            localStorage.getItem("home_details_neighborhood_id") || null;
          if (home_details_neighborhood_id)
            this.details.neighborhood_id = parseInt(
              home_details_neighborhood_id
            );
        });
      });
    },
  },
  mounted() {
    this.getCities();
    this.getPeriods();

    // this.$store.dispatch(`home/listCities`).then((res) => {
    //   this.cityList =  res.data;
    // });

    this.$store.dispatch(`home/listTripStatus`).then((res) => {
      this.tripStatusList = res.data;
    });

    if (this.userDriver) {
      this.filter.city_id = this.userDriver.admin.city_id;
    }

    let home_city_id = localStorage.getItem("home_city_id") || null;
    let home_date = localStorage.getItem("home_date") || null;
    let home_period_id = localStorage.getItem("home_period_id") || null;
    if (home_date) {
      this.filter.date = home_date;
      if (home_city_id) this.filter.city_id = parseInt(home_city_id);
      if (home_period_id) this.filter.period_id = parseInt(home_period_id);
      if (
        this.userDriver &&
        this.userDriver.admin &&
        this.userDriver.admin.city_id
      ) {
        this.filterData2();
      } else {
        this.filterAdminData2();
      }
    }

    // this.getDataFromApi();
  },
};
</script>


<style>
.custom-error .v-input__slot {
  border: 1px solid red;
}

.custom-driver-scroll {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

.custom-driver-table {
  border-collapse: collapse;
  width: 100%;
}

.custom-driver-table th,
.custom-driver-table td {
  text-align: center;
  padding: 10px;
  border: 1px solid #ddd;
}

.custom-driver-table th {
  background-color: #3f51b5 !important;
  color: #fff;
}

.checkmark-icon,
.cross-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 3px;
  text-align: center;
  line-height: 20px;
  font-size: 14px;
  font-weight: bold;
}

.checkmark-icon {
  background-color: #4caf50;
  color: #fff;
}

.cross-icon {
  background-color: #f44336;
  color: #fff;
}

/* @media only screen and (max-width: 600px) {
 .custom-driver-table {
  width: 100%;
  border-collapse: collapse;
}

.custom-driver-table th,
.custom-driver-table td {
  padding: 10px;
  text-align: center;
  border: 1px solid #ddd;
}

.custom-driver-table th {
  background-color: #3f51b5 !important;
  color: #fff;
}

  } */

.area_div_block {
  padding: 0 0;
  word-wrap: break-word;
  white-space: initial;
  cursor: pointer;
  margin: 10px 0;
}
/* .area_div_block:hover{
        background:#00fcff;
        color: #000;
        font-weight: bold;
  } */

#eventsTable {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#eventsTable td,
#eventsTable th {
  border: 1px solid #ddd;
  padding: 5px;
}

#eventsTable tr {
  cursor: pointer;
}

#eventsTable tr:hover td {
  background-color: #204E78 !important;
  color: #fff !important;
}

#eventsTable th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #204e78;
  color: white;
}

.adminEventsDiv .row {
    margin: 0 !important;
    width: 110% !important;
}
</style>